import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Wheel } from 'react-custom-roulette';
import { toast } from 'react-toastify';
import ReportCard from './ReportCard';
import { post } from '../helpers/api_helper';
import confetti from 'canvas-confetti';


const QuizStepBox = ({ step, steps, question, options, type, onSelect, maxQuentity, setCount, count }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        onSelect(option);
    };

    const decreaseFunction = () => {
        if (count >= 1) {
            setCount(count - 1)
        }
    }
    const increaseFunction = () => {
        setCount(count + 1)
    }

    // console.log('maxQuentity', maxQuentity)

    return (
        <div className='Quiz_box'>
            {/* <h2>Step {step}</h2> */}
            <div className='left_section'>
                <div className='question_header'>
                    <p className='question'> {step + 1}. {question}</p>
                    <div className='total_question'>
                        {step + 1}/{steps?.length}
                    </div>
                </div>
                {
                    type == "MCQ" ?
                        <div className='answer_list'>
                            {options.map((option, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleOptionSelect(option)}
                                    className={selectedOption === option ? 'selected' : ''}
                                >
                                    <span className={selectedOption === option ? 'active' : ''}></span> {option?.title}
                                </button>
                            ))}
                        </div>
                        :
                        <div className='number-section'>
                            <button onClick={decreaseFunction}>&#x002D;</button>
                            <input type='number' className='form-control' value={count} />
                            <button onClick={increaseFunction} disabled={maxQuentity < count + 1}>+</button>
                        </div>
                }


            </div>

        </div>
    );
};

export default function Quiz(props) {

    const { submitted, setSubmitted, setLoading } = props;

    const random = Math.floor(Math.random() * 5) + 1;
    // random == 3 ? 3 : random

    const couponNumber = [
        { title: 'You won a coupon for 15% off on iCubeswire products.' },
        { title: 'You won a coupon for 25% off on iCubeswire products.' },
        { title: 'You won a coupon for Amazon voucher worth INR 1000.' },
        { title: 'You won a coupon for Amazon voucher worth INR 2000.' },
        { title: 'Better luck next time' },

    ]

    const [currentStep, setCurrentStep] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [mustSpin, setMustSpin] = useState(false);
    const [runSpin, setRunSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(undefined);
    const [steps, setSteps] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [finish, setFinish] = useState(false);
    const [result, setResult] = useState(undefined)
    const [color, setColor] = useState(undefined)
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [pdfpopup, setPdfpopup] = useState(false);
    const [copyText, setCopyText] = useState(false);
    const [coupon, setCoupon] = useState(undefined)
    const [userId, setUserId] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [mobileNumber, setMobileNumber] = useState(undefined)

    const code = "rfguyugu6556hjbjh";

    console.log('mobileNumber', mobileNumber)
    useEffect(() => {
        let user = localStorage.getItem('user');
        user = JSON.parse(user)
        setUserId(user?._id);
        setUser(user);
    }, [])
    // console.log('userId', userId);
    const fire = (particleRatio, opts) => {
        confetti({
            ...opts,
            particleCount: Math.floor(200 * particleRatio),
        });
    };

    const confettiExplosion = (origin) => {
        fire(0.25, { spread: 26, startVelocity: 55, origin });
        fire(0.2, { spread: 60, origin });
        fire(0.35, { spread: 100, decay: 0.91, origin });
        fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, origin });
        fire(0.1, { spread: 120, startVelocity: 45, origin });
    };

    // Function to trigger confetti on modal open
    const triggerConfettiOnModalOpen = () => {
        const modal = document.getElementById('myModal');
        const rect = modal.getBoundingClientRect();

        const center = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        };

        const origin = {
            x: center.x / window.innerWidth,
            y: center.y / window.innerHeight,
        };

        confettiExplosion(origin);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyText(true)
        }).catch(err => {
            console.error("Could not copy text: ", err);
        });
    };

    useEffect(() => {
        setTimeout(() => {
            setCopyText(false)
        }, 500)
    }, [copyText])

    // console.log('prizeNumber', props, userId)

    useEffect(() => {
        if (props?.questions?.length) {
            setSteps(props?.questions);
            setPrizeNumber(props?.data?.prizeNumber)
            setCoupon(props?.data?.coupon?.giftCode)
        }
    }, [props?.questions])

    const spinData = [
        { option: '15% off on iCubeswire products', style: { backgroundColor: 'skyblue', textColor: 'white' } },
        { option: '25% off on iCubeswire products', style: { backgroundColor: 'red', textColor: 'white' } },
        { option: 'Amazon voucher worth INR 1000', style: { backgroundColor: 'red', textColor: 'white' } },
        { option: 'Amazon voucher worth INR 2000', style: { backgroundColor: 'red', textColor: 'white' } },
        { option: 'Better luck next time', style: { backgroundColor: 'green', textColor: 'white' } }
    ];

    const handleSpinClick = () => {
        if (!runSpin) {
            // const newPrizeNumber = Math.floor(Math.random() * spinData.length);
            // setPrizeNumber(newPrizeNumber);
            setRunSpin(true);
        }
    }

    const handleOptionSelect = (option) => {
        setCurrentAnswer(option);
        // setAnswers([...answers, option]);
        // setCurrentStep(currentStep + 1);
        // props?.setCurrentStep(currentStep + 1);
    };

    const handleNextStep = () => {
        if (currentAnswer) {
            if (currentStep < steps.length) {
                let temp = Object.assign([], answers);
                let found = temp.findIndex(x => x?.ques == steps[currentStep]?.title);
                if (found != -1) {
                    if (currentAnswer?.quesType == "MCQ") {
                        temp[found].option = currentAnswer?.title;
                        temp[found].marks = currentAnswer?.marks;
                    } else {
                        temp[found].option = count;
                        temp[found].marks = count * steps[currentStep]?.unit;
                    }
                } else {
                    if (steps[currentStep]?.quesType == "MCQ") {
                        temp.push({ ques: steps[currentStep]?.title, option: currentAnswer?.title, marks: currentAnswer?.marks });
                    } else {
                        temp.push({ ques: steps[currentStep]?.title, option: count, marks: count * steps[currentStep]?.unit });
                    }
                }
                setAnswers(temp);
                if (currentStep === steps.length - 1) {
                    submitAnswer(temp);
                } else {
                    setCurrentAnswer(null);
                    setCount(0);
                    setCurrentStep(currentStep + 1);
                    props?.setCurrentStep(currentStep + 1);
                }
            }
        } else {

            if (steps[currentStep]?.quesType == 'MCQ') {
                toast.error('Please select an option');
                return
            }
            let temp = Object.assign([], answers);
            let found = temp.findIndex(x => x?.ques == steps[currentStep]?.title);
            // console.log("else part of next found", found);
            if (found != -1) {
                temp[found].option = count;
                temp[found].marks = count * steps[currentStep]?.unit;
            } else {
                temp.push({ ques: steps[currentStep]?.title, option: count, marks: count * steps[currentStep]?.unit });
            }
            setAnswers(temp);
            if (currentStep === steps.length - 1) {
                submitAnswer(temp);
            } else {
                setCurrentAnswer(null);
                setCount(0);
                setCurrentStep(currentStep + 1);
                props?.setCurrentStep(currentStep + 1);
            }
        }
    };

    // console.log("answer", answers, typeof count, typeof steps[currentStep]?.unit)

    const handlePrevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            props?.setCurrentStep(currentStep - 1);
            let temp = Object.assign([], answers);
            temp.pop();
            setAnswers(temp);
        }
    };

    const submitAnswer = (test) => {
        console.log('test', test)
        setMobileNumber(user?.mobile)
        setLoading(true);
        let won = "", total = 0;
        test?.forEach((elem) => {
            // if (elem?.quesType == "MCQ")
            total += elem?.marks;
        });
        console.log("total", total, steps?.length);
        if (total > 60) {
            won = "Very high Intake";
            // setPrizeNumber(4);
            setColor('#c73731')
        }
        else if (total < 60 && total > 50) {
            won = "High intake";
            // setPrizeNumber(3);
            setColor('#blue')

        }
        else if (total < 50 && total > 35) {
            won = "Acceptable but nearing high intake";
            // setPrizeNumber(2);
            setColor('#fff900')
        }
        else if (total < 35 && total > 25) {
            won = "Slightly above optimal, but still within an acceptable range";
            // setPrizeNumber(1);
            setColor('#ffB801')
        } else if (total <= 25) {
            won = 'Optimal';
            // setPrizeNumber(0);
            setColor('#86e229')
        }

        // if ((total / 10)?.toFixed(0) <= 2.5) {

        // }
        // if ((total / 10)?.toFixed(0) > 2.5 && (total / 10)?.toFixed(0) <= 5) {

        // }
        // if ((total / 10)?.toFixed(0) > 5 && (total / 10)?.toFixed(0) <= 7.5) {

        // }
        // if ((total / 10)?.toFixed(0) > 7.5 && (total / 10)?.toFixed(0) <= 10) {

        // }
        setResult((total).toFixed(0))
        // console.log('result', result1)
        let body = {
            userId: userId,
            test: test,
            won: spinData[prizeNumber]?.option,
            result: total,
            prizeNumber: prizeNumber,
            giftCode: coupon,
            expiryDate: props?.data?.expiryDate
        }
        // console.log("body to submit", body, total);
        post("question/answer", body)
            .then(res => {
                setLoading(false)
                if (res?.statusCode == 200) {
                    setSubmitted(true);
                    props?.setStepForm(false);
                    props?.setReportShow(true);

                } else {
                    toast.error(res?.message)
                }
            }).catch(err => {
                setLoading(false);
                console.log("error while submitting answer", err);
                toast.error("Something Went Wrong!");
            })
    }

    // console.log('result new', result)


    return (
        <>
            <div className='quiz-page'>


                {!submitted && steps?.length ? (
                    <>
                        <QuizStepBox
                            step={currentStep}
                            steps={steps}
                            question={steps[currentStep].title}
                            options={steps[currentStep].options}
                            type={steps[currentStep].quesType}
                            maxQuentity={steps[currentStep].maxQuentity}
                            onSelect={handleOptionSelect}
                            setTotal={setTotal}
                            setCount={setCount}
                            count={count}
                        />
                        <div className='button_group'>
                            <button onClick={handlePrevStep} type="" class="btn btn-default progress-button btn-submit">
                                Previous
                                <span class="mask"></span>
                            </button>

                            <button onClick={handleNextStep} type="" class="btn btn-default progress-button btn-submit">
                                Next
                                <span class="mask"></span>
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='complete_section'>
                        {
                            mobileNumber ? <div className='tracking_box' >
                                <img src={`https://tracking.icubeswire.co/aff_a?offer_id=6219&adv_sub1=${mobileNumber}`} width='1' height='1' id='pixelcodeurl' />
                            </div>
                                :
                                null
                        }
                        <div className='report_card'>
                            <ul>
                                <li className={result <= 25 ? 'active' : null}>Optimal</li>
                                <li className={result < 35 && result > 25 ? 'active' : null}>Slightly above</li>
                                <li className={result < 50 && result > 35 ? 'active' : null}>Acceptable</li>
                                <li className={result < 60 && result > 50 ? 'active' : null}>High intake</li>
                                <li className={result > 60 ? 'active' : null}>Very high</li>
                            </ul>
                            <div className='result_section' style={{ border: `1px solid ${color}` }}>
                                <section class="summary">
                                    <div>
                                        <p class="score-box">
                                            <span class="score-value">{result}</span>
                                            <p className=''>grams/day</p>
                                            {/* <span class="score-max">of 100</span> */}
                                        </p>
                                        <div class="score-rank">
                                            {
                                                result <= 25 ? <div className='advixe_box'>
                                                    <h4>0-25 grams/day</h4>
                                                    <p>Your sugar consumption is impressively within the optimal range! To help you
                                                        maintain this healthy habit, consider these tips for sustaining your success
                                                        and living even healthier.</p>
                                                </div> : null
                                            }
                                            {
                                                result < 35 && result > 25 ? <div className='advixe_box'>
                                                    <h4>26-35 grams/day</h4>
                                                    <p>Your sugar intake is slightly above optimal but still within an acceptable range.
                                                        To help you achieve the optimal level and maintain a healthy habit, consider
                                                        these tips for sustaining your success and enhancing your well-being.</p>
                                                </div> : null
                                            }
                                            {
                                                result < 50 && result > 35 ? <div className='advixe_box'>
                                                    <h4>36-50 grams/day</h4>
                                                    <p>Your daily sugar consumption is approaching high levels and needs attention. Consider
                                                        these essential tips to reduce your sugar intake and improve your health.</p>
                                                </div> : null
                                            }
                                            {
                                                result < 60 && result > 50 ? <div className='advixe_box'>
                                                    <h4>51-60 grams/day</h4>
                                                    <p>Your sugar consumption is higher than the recommended limit. To help balance your
                                                        sugar intake and stay healthy, consider these crucial tips.</p>
                                                </div> : null
                                            }
                                            {
                                                result > 60 ? <div className='advixe_box'>
                                                    <h4>Over 60 grams/day</h4>
                                                    <p>Your sugar consumption is alarmingly high and poses significant health risks. To reduce
                                                        your intake and improve your health, consider these essential tips:</p>
                                                </div> : null
                                            }

                                            <div className='result_btn_group'>
                                                {result <= 25 ? <a href={require('../assets/img/points1.pdf')} download>Download </a> : null}
                                                {result < 35 && result > 25 ? <a href={require('../assets/img/points2.pdf')} download>Download </a> : null}
                                                {result < 50 && result > 35 ? <a href={require('../assets/img/points3.pdf')} download>Download </a> : null}
                                                {result < 60 && result > 50 ? <a href={require('../assets/img/points4.pdf')} download>Download </a> : null}
                                                {result > 60 ? <a href={require('../assets/img/points5.pdf')} download>Download </a> : null}

                                                <button onClick={() => setPdfpopup(true)}>Know More</button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className='spin-1'>
                            {/* <span>Spin the wheel to win your rewards</span> */}
                            {/* <button onClick={() => setMustSpin(true)} className='shine'>
                               Visit our website
                            </button> */}
                            <a className='spin-1_btn' href='https://www.sugarfree-india.com/' target='_blank'>Visit our website</a>
                        </div>
                    </div>
                )}
            </div >
            <div className={mustSpin ? 'modal_popup active' : 'modal_popup'}>
                {/* <h3>This demo is crafted specially for corporates & small or big bunesses. Giving it a more standard and decent look it appeals everybody. </h3> */}
                <div className='wheeler_main'>
                    <div className='wheeler_box'>
                        <Wheel
                            mustStartSpinning={runSpin}
                            prizeNumber={prizeNumber}
                            data={spinData}
                            textDistance={55}
                            fontSize={[13]}
                            onStopSpinning={() => {
                                setFinish(true);
                                triggerConfettiOnModalOpen()
                            }}

                            pointerProps={{ marginBottom: 50 }}
                        />
                        <button onClick={handleSpinClick} className='spin_button'>Spin<br />now</button>
                    </div>
                </div>
            </div>
            <div className={finish ? 'modal_popup1 active' : 'modal_popup1'} id='myModal'>
                {/* <iframe src={`https://tracking.icubeswire.co/aff_a?offer_id=5824&goal_id=9091&adv_sub1=${user?.mobile}&adv_sub2=${user?.name}`} width='1' height='1' id='pixelcodeurl' ></iframe>
                {finish ?
                    <iframe src={`https://tracking.icubeswire.co/aff_a?offer_id=5824&goal_id=9120&adv_sub1=${user?.mobile}&adv_sub2=${user?.name}`} width='1' height='1' id='pixelcodeurl' ></iframe>
                    : null} */}
                <button className='close_button' onClick={() => window.location.reload()}>
                    <i class="fa fa-times"></i>
                </button>
                <div className='winning_page'>
                    {
                        prizeNumber == 4 ? <h3> Better luck next time! </h3> : <h3> Congratulations! </h3>
                    }
                    {
                        prizeNumber == 4 ? null : <p>{couponNumber[prizeNumber]?.title}</p>
                    }


                    {/* <h5>{user?.name}</h5> */}
                    {
                        prizeNumber == 4 ? null :

                            <div className='cuponcode_copy'>
                                <button onClick={() => copyToClipboard(coupon)}>
                                    {
                                        copyText ? <i class="fa fa-check"></i> : <i class="fa fa-copy"></i>
                                    }

                                </button>
                                <span>{coupon}</span>
                            </div>
                    }
                    <a href='https://www.sugarfree-india.com/' target='_blank'>Visit our website</a>
                </div>
            </div >
            <div className={pdfpopup ? 'pdf_popup active' : 'pdf_popup'}>
                <div className='pdf_popup_body'>
                    <button className='close_button' onClick={() => setPdfpopup(false)}>
                        <i class="fa fa-times"></i>
                    </button>
                    {result <= 25 ? <iframe src={require('../assets/img/points1.pdf')}></iframe> : null}
                    {result < 35 && result > 25 ? <iframe src={require('../assets/img/points2.pdf')}></iframe> : null}
                    {result < 50 && result > 35 ? <iframe src={require('../assets/img/points3.pdf')}></iframe> : null}
                    {result < 60 && result > 50 ? <iframe src={require('../assets/img/points4.pdf')}></iframe> : null}
                    {result > 60 ? <iframe src={require('../assets/img/points5.pdf')}></iframe> : null}
                </div>
            </div>
        </>
    )
}